.MuiDateRangeCalendar-root > div:nth-of-type(1) {
  display: none;
}
@media (max-width: 1225px) {
  .MuiPickersLayout-shortcuts {
    display: none;
  }
}

.custom-y-padding-bottom .MuiChartsAxis-directionY .MuiChartsAxis-label {
  transform: translateX(-10px) !important;
}
